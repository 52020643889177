export default grapesjs.plugins.add('ImageAndText', (editor, options) => {
	editor.BlockManager.add('ImageAndText', {
		category: 'Commerce',
		label: 'Image and text',
		attributes: { class: 'fa fa-gift' },
		content: `<div class="w-100 mb-5 image-and-text-container" data-gjs-type="ImageAndText" data-initial-state="true">
			Component preview: configure your image and text component!
			<br /> 
			<img class="img-fluid" src="` + CCRZ.pagevars.themeBaseURL + `images/ccc-preview-image-text.png" style="margin: auto; display: block;" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false" />
		</div>`
	});

	// Get DomComponents module
	var comps = editor.DomComponents;
	
	// Get the model and the view from the default Component type
	var defaultType = comps.getType('default');
	var defaultModel = defaultType.model;
	var defaultView = defaultType.view;

	// start configuration Teaser-Tile-Big
	comps.addType('ImageAndText', {
		// Define the Model
		model: defaultModel.extend({
			// Extend default properties
			defaults: Object.assign(
				{},
				defaultModel.prototype.defaults, {
					// Can't drop other elements inside it
					droppable: false,
					// Traits (Settings)
					traits: [
						{
							type: 'select',
							label: 'Backgound color',
							name: 'backgroundColor',
							options: [ // Array of options
								{ id: 'white', name: 'white'},
								{ id: 'grey', name: 'grey'}
							]
						},
						{
							type: 'select',
							label: 'Image position',
							name: 'imagePosition',
							options: [ // Array of options
								{ id: 'left', name: 'left'},
								{ id: 'right', name: 'right'}
							]
						},
						{
							type: 'text', // Type of the trait
							label: 'Image path', // The label you will see in Settings
							name: 'imgSrc', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Image title', // The label you will see in Settings
							name: 'imgTitle', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Headline first', // The label you will see in Settings
							name: 'headlineFirst', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Headline second', // The label you will see in Settings
							name: 'headlineSecond', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Content text', // The label you will see in Settings
							name: 'contentText', // The name of the attribute/property to use on component
						}, {
							type: 'text', // Type of the trait
							label: 'Primary button text', // The label you will see in Settings
							name: 'btnPriTxt', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Primary button link', // The label you will see in Settings
							name: 'btnPriHref', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Is Visible From', // The label you will see in Settings
							name: 'cccStartDate', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Is Visible To', // The label you will see in Settings
							name: 'cccEndDate', // The name of the attribute/property to use on component
						}
					],
					attributes: {
						backgroundColor: '',
						imagePosition: '',
						imgSrc: '',
						imgTitle: '',
						headlineFirst: '',
						headlineSecond: '',
						contentText: '',
						btnPriTxt: '',
						btnPriHref: '',
						cccStartDate: '',
						cccEndDate: ''
					}
				}
			),
			init() {
				this.on('change:attributes:backgroundColor', this.handleTraitChange);
				this.on('change:attributes:imagePosition', this.handleTraitChange);
				this.on('change:attributes:imgSrc', this.handleTraitChange);
				this.on('change:attributes:imgTitle', this.handleTraitChange);
				this.on('change:attributes:headlineFirst', this.handleTraitChange);
				this.on('change:attributes:headlineSecond', this.handleTraitChange);
				this.on('change:attributes:contentText', this.handleTraitChange);
				this.on('change:attributes:btnPriTxt', this.handleTraitChange);
				this.on('change:attributes:btnPriHref', this.handleTraitChange);
				this.on('change:attributes:cccStartDate', this.handleTraitChange);
				this.on('change:attributes:cccEndDate', this.handleTraitChange);
			},
			handleTraitChange() {
				CCRZ.testaus = this;
				this.attributes.content = this.generateHTML(false);
				this.view.el.innerHTML = this.generateHTML(false);
				$(this.view.el).removeAttr('data-initial-state');
				delete this.view.attr['data-initial-state'];
				delete this.attributes.attributes['data-initial-state'];
			},
			toHTML: function () {
				return this.generateHTML(true);
			},
			generateHTML(includeParentDOMElement) {
				let backgroundClass = '';
				let orderClass = '';
				let imageAreaClass = 'pl-xl-0';
				let textAreaClass = 'pr-xl-0';

				if (this.getAttributes().backgroundColor === 'white') {
					backgroundClass = 'hdm-bg-primary';
				} else if (this.getAttributes().backgroundColor === 'grey') {
					backgroundClass = 'hdm-bg-secondary';
				}
			
				if (this.getAttributes().imagePosition === 'right') {
					orderClass = 'order-lg-last';
					imageAreaClass = 'pr-xl-0';
					textAreaClass = 'pl-xl-0';
				}

				if (typeof includeParentDOMElement == 'undefined') {
					includeParentDOMElement = false;
				}

				let elementString = '';
				let timedAttributes = '';

				if (includeParentDOMElement) {
					if (this.getAttributes().cccStartDate && this.getAttributes().cccEndDate) {
						timedAttributes = ' cccStartDate="' + this.getAttributes().cccStartDate + '" cccEndDate="' +  this.getAttributes().cccEndDate + '"';
					}
					elementString = elementString + '<div class="w-100 image-and-text-container ' + backgroundClass + '" data-gjs-type="ImageAndText"' + timedAttributes + '>';
				}

				// elementString += '<div class="w-100 image-and-text-container ' + backgroundClass + '">';
				elementString += 	'<div class="container-fluid py-60">';
				elementString += 		'<div class="row">';
				elementString += 			'<div class="col-12 col-lg-6 mb-5 mb-lg-0 d-flex justify-content-center' + ' ' + orderClass + ' ' + imageAreaClass + '">';
				elementString += 				'<img class="img-fluid" style="object-fit: cover;" src="' + this.getAttributes().imgSrc + '" alt="' + this.getAttributes().imgTitle + '" title="' + this.getAttributes().imgTitle + '" />';
				elementString += 			'</div>';
				elementString += 			'<div class="col-12 col-lg-6 d-flex flex-column align-items-start' + ' ' + textAreaClass + '">';
				if (this.getAttributes().headlineFirst)
				elementString += 				'<p class="image-and-text-firstheader">' + this.getAttributes().headlineFirst + '</p>';
				if (this.getAttributes().headlineSecond)
				elementString += 				'<p class="image-and-text-secondheader">' + this.getAttributes().headlineSecond + '</p>';
				elementString += 				'<div class="slider-content">';
				elementString += 					'<div class="mb-4">' + this.getAttributes().contentText + '</div>';
				if (this.getAttributes().btnPriTxt)
				elementString += 					'<a class="btn btn-primary btn-lg mr-3" href="' + this.getAttributes().btnPriHref + '">' + this.getAttributes().btnPriTxt + '</a>';
				elementString += 				'</div>';
				elementString += 			'</div>';
				elementString += 		'</div>';
				elementString += 	'</div>';
				// elementString += '</div>';


				if (includeParentDOMElement) {
					elementString += '</div>';
				}

				return elementString;
			}
		},
			// The second argument of .extend are static methods and we'll put inside our
			// isComponent() method. As you're putting a new Component type on top of the stack,
			// not declaring isComponent() might probably break stuff, especially if you extend
			// the default one.
			{
				isComponent(el) {
					if (el.getAttribute && el.getAttribute('data-gjs-type') == 'ImageAndText') {
						return {
							type: 'ImageAndText'
						};
					}
				}
			}
		)
	});
});
